<template>
  <ul class="itemDetails">
    <li v-for="(item, index) in orderList" :key="index">
      <div :class="[flag == index ? 'choiseLi' : '', 'liMain']">
        <div class="topMain">
          <div class="infoBox">
            <p class="timeBox">
              {{ item.orderRequest.dtStart | formatDate('yyyy-MM-dd hh:mm') }}
            </p>
            <div class="borderBox">
              <a>{{ item.orderRequest.itsRequestData.carModus }}</a>
              <a>{{ item.orderRequest.itsRequestData.carType }}</a>
              <a>{{ item.qty }}台</a>
            </div>
          </div>
        </div>
        <div style="padding: 0 .1rem;" class="addressBox" v-if="item.orderRequest && item.orderRequest.itsRequestData && item.orderRequest.itsRequestData.isSpecial">
          <span>
            <i class="greenBc"></i>
            {{ item.orderRequest.itsRequestData.departure }}
          </span>
          <span>
            <i class="orangeBc"></i>
            {{ item.orderRequest.itsRequestData.destination }}
          </span>
        </div>
        <span class="descBox">{{ item.orderRequest.itsRequestData.descr || '暂无备注' }}</span>
        <!-- 展示司机信息详情 -->
        <!-- <template v-if="driverData&&showData">
          <div class="currentDriver">
            <div>司机信息</div>
            <div>{{ driverData.name }}({{ driverData.phone }})</div>
          </div>
          <div class="currentDriver">
            <div>车辆信息</div>
            <div>{{ driverData.carModel }}{{ driverData.licensePlate }}</div>
          </div>
        </template> -->
        <div class="price_info">{{ item.amount || 0 | currencyFormatter }}</div>
      </div>
      <!-- <div
        class="tripListBox"
        v-if="item.orderRequest&&
          item.orderRequest.itsRequestData &&
          tem.orderRequest.itsRequestData.tripList&&
          item.orderRequest.itsRequestData.tripList[0] &&
          item.orderRequest.itsRequestData.tripList[0].departure
        "
      >
        <div
          v-for="(trip, tindex) in item.orderRequest.itsRequestData.tripList"
          :key="tindex"
        >
          <template v-if="item.isActive">
            <p class="flight">{{ trip.flight }}</p>
            <div class="addressBox">
              <span>{{ trip.departure }}</span>
              <i></i>
              <i></i>
              <i></i>
              <span>{{ trip.destination }}</span>
            </div>
            <p>
              <span>用车时间</span>
              <a>{{ trip.dtStart }}</a>
            </p>
            <p>
              <span>乘车人</span>
              <a>{{ trip.name }}</a>
            </p>
            <p>
              <span>乘车人电话</span>
              <a>{{ trip.phone }}</a>
            </p>
          </template>
        </div>
        <div
          :class="['shirTop', item.isActive && 'trastormDiv']"
          @click="shirinkBtn(item)"
        >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconright" />
          </svg>
        </div>
      </div> -->
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    orderList: {
      type: Array
    }
  },
  data() {
    return {
      flag: -1,
      isActive: -1,
      driverData: {},
      showData:false
    };
  },
  mounted() {
    setTimeout(() => {
      console.log('1111133', this.orderList);
      // this.getCurrentDriver();

    }, 1000);

    this.orderList &&
      this.orderList.map(v => {
        return { ...v, isActive: false };
      });
  },
  methods: {
    shirinkBtn(item) {
      console.log(item);
      item.isActive = !item.isActive;
      this.$forceUpdate();
    },
    getCurrentDriver() {
      if (this.orderList[0]?.orderId) {
        let params = {
          orderId: this.orderList[0].orderId
        };
        this.services.currentDrivers(params).then(res => {
          this.driverData = res.content;
          this.showData=true;
          // console.log('driverData',this.driverData);
        });
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/tenant/carDetail.scss';
</style>
