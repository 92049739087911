<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-26 10:35:48
-->
<template>
  <div class="carDetailMain">
    <div class="topBack"></div>
    <iHeader
       backType="blank"
      :backEvent="true"
      @back-event="carDetailGoBack()"
      :back="true"
    >
    <div slot="headText">
        <span class="head-txt">
          {{$route.query.noShowFoot?'需求信息':isQuote}}
        </span>
    </div>
    </iHeader>
   
    <div class="carDetailBox">
      <supplierInfo :seventData="seventData"></supplierInfo>
    </div>
    <div class="carDetailBox carDetailBoxmain">
        <carDetailInfo :orderList="seventData.orderExpenseItems"></carDetailInfo>
    </div>
    <tenantFoot v-if="!this.SmartStorage.get('viewOnly')&&!$route.query.noShowFoot&&isLoade" ref="tenantFoot"></tenantFoot>
  </div>
</template>
<script>
import supplierInfo from "@/views/common/tenant/supplierInfo";
import carDetailInfo from "@/views/common/tenant/carDetailInfo";
import tenantFoot from "@/views/common/tenantFoot";
import filterEmoji from "@/views/controllers/filterEmoji.js";
export default {
  mixins: [filterEmoji],
  data() {
    return {
      isLoade:false,
      seventData: {},
      isQuote: "",
      isShowFoot: false
    };
  },
  created() {
    this.setRouteListeners("carDetailGoBack");
    this.$root.$eventHub.$on("carDetailGoBack", data => {
      this.carDetailGoBack();
      this.$root.$eventHub.$off("carDetailGoBack"); //解绑当前监听，否则下轮监听还会持续叠加
    });
    this.getDetail();
  },
   components: {
    supplierInfo,
    carDetailInfo,
    tenantFoot
  },
  methods: {
    carDetailGoBack() {
      this.removeRouteListeners("carDetailGoBack");
      this.$router.back();
    },
    getDetail() {
      this.isLoade=false;
      let params = {
        filters: {
          orderId: this.SmartStorage.get("carOrder").orderId
        }
      };
      this.services.GetEOrder(params).then(res => {
        for (let i = 0; i < res.content?.orderExpenseItems?.length; i++) {
            res.content.orderExpenseItems[i].orderRequest.itsRequestData.descr=this.StringExchangeEmoji(res.content.orderExpenseItems[i]?.orderRequest?.itsRequestData?.descr)
          }
        this.seventData = res.content;
         this.SmartStorage.set("taskId", res.content.taskId);
        !this.$route.query.isTenant &&
          this.SmartStorage.set("taskId", res.content.taskId);
          switch (res.content.status) {
          case 10:
            this.isQuote = '等待车队报价'
            break;
          case 20:
            this.isQuote = '当前订单车队无资源'
            break;
          case 50:
            this.isQuote = '等待车队发起结算'
            break;
          case -1:
            this.isQuote = '已取消'
            break;
          default:
            this.isQuote = '确认报价'
            break;
        }
        this.isLoade=true;
      });
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/tenant/carDetail.scss';
</style>