<template>
  <div  class="supplierMain">
    <div class="supplier_title ">
      <p>
        <img class="supplierImg mr5" v-if="seventData.supplierThumbnail" :src="seventData.supplierThumbnail" />
        <!-- <img class="supplierImg mr5"  v-else src="../../../assets/images/icar.png"  /> -->
        <svg class="icon icon-normal" aria-hidden="true" v-else>
          <use xlink:href="#icon-zhongzhigongyingshangcheduiLOGO" />
        </svg>
        {{ seventData.supplierName }}
      </p>
      <div class="cityBox">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-weizhi" />
        </svg>
        {{ seventData.itsExtData && seventData.itsExtData.CityDictVal }}
      </div>
      <div class="contactBoz">
        <span @click.stop="ihtml.telCall(contact.mobile)" v-for="(contact, c2Index) in seventData.supplierContacts" :key="c2Index">{{ contact.name }}({{ contact.mobile }})</span>
      </div>
    </div>
    <div class="supplier_btm">
      <div class="priceBox">
        总报价金额
        <p>{{ seventData.quoteTotalAmount || 0 | currencyFormatter }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { device } from '@/views/utils/common';
export default {
  props: ['seventData'],
  data() {
    return {};
  
  },
  created() {
    this.device=device();
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/tenant/carDetail.scss';
</style>
